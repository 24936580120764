// Chat.tsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import {
  apiSendChat,
  RestrictedAsaChat,
  apiGetAllAsaChats,
  apiGetAsaChatMessages,
  AsaChatMessage,
  apiDeleteAsaChat,
  apiCreateAsaChat,
} from '../api/asaChat';
import {
  FaArrowUp,
  FaStethoscope,
  FaHandHoldingMedical,
  FaAllergies,
  FaFlask,
  FaCapsules,
} from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { formatGptTextToJSX } from '../utils/formatGptTextToJSX';
import { LanguageContext } from '../contexts/LanguageContext';
import Iridescence from '../components/Iridescence/Iridescence';

const asaProfileImage = '/messageart.jpeg';

const translations = {
  en: {
    title: "How can I assist you today?",
    promptHeading: "Try one of these questions!",
    sendPlaceholder: "Type your question...",
    login: "Login",
    register: "Register",
    noChatText: "Don't have an account?",
    emergencyText: "In emergencies, dial 112",
  },
  is: {
    title: "Hvernig get ég aðstoðað þig í dag?",
    promptHeading: "Prófaðu eitt af þessum spurningum!",
    sendPlaceholder: "Sláðu inn spurningu...",
    login: "Innskrá",
    register: "Skráðu þig",
    noChatText: "Ertu ekki með aðgang?",
    emergencyText: "Í neyðartilfellum hringið í 112",
  },
  pl: {
    title: "Jak mogę Ci dzisiaj pomóc?",
    promptHeading: "Wypróbuj jedno z tych pytań!",
    sendPlaceholder: "Wpisz swoje pytanie...",
    login: "Zaloguj się",
    register: "Zarejestruj się",
    noChatText: "Nie masz konta?",
    emergencyText: "W nagłych wypadkach dzwoń pod numer 112",
  },
};

const promptExamples = {
  en: [
    {
      text: "I would like advice on musculoskeletal pain",
      icon: <FaHandHoldingMedical className="text-green-400" />,
    },
    {
      text: "I'm unsure which medication is best...",
      icon: <FaCapsules className="text-blue-400" />,
    },
    {
      text: "Can you help explain my blood test results?",
      icon: <FaFlask className="text-yellow-400" />,
    },
    {
      text: "What can I do if I have allergy symptoms?",
      icon: <FaAllergies className="text-red-400" />,
    },
  ],
  is: [
    {
      text: "Mig langar að fá ráðgjöf um verki í stoðkerfi",
      icon: <FaHandHoldingMedical className="text-green-400" />,
    },
    {
      text: "Ég er óviss um hvers konar lyf hentar best...",
      icon: <FaCapsules className="text-blue-400" />,
    },
    {
      text: "Getur þú hjálpað mér að útskýra niðurstöður úr blóðprufu?",
      icon: <FaFlask className="text-yellow-400" />,
    },
    {
      text: "Hvað get ég gert ef ég er með einkenni um ofnæmi?",
      icon: <FaAllergies className="text-red-400" />,
    },
  ],
  pl: [
    {
      text: "Chciałbym uzyskać poradę dotyczącą bólu mięśniowo-szkieletowego",
      icon: <FaHandHoldingMedical className="text-green-400" />,
    },
    {
      text: "Nie jestem pewien, które leki będą najlepsze...",
      icon: <FaCapsules className="text-blue-400" />,
    },
    {
      text: "Czy możesz pomóc mi wyjaśnić wyniki badań krwi?",
      icon: <FaFlask className="text-yellow-400" />,
    },
    {
      text: "Co mogę zrobić, jeśli mam objawy alergii?",
      icon: <FaAllergies className="text-red-400" />,
    },
  ],
};

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<AsaChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [chats, setChats] = useState<RestrictedAsaChat[]>([]);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [titleText, setTitleText] = useState('');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const toggleSidebar = () => setIsSidebarCollapsed((prev) => !prev);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const auth = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  if (!languageContext) {
    throw new Error("LanguageContext is not available");
  }
  const { language } = languageContext;
  const navigate = useNavigate();
  const params = useParams();

  // Type assertion: tell TypeScript that language is one of the keys.
  const langKey = language as keyof typeof translations;
  const t = translations[langKey];
  const currentPrompts = promptExamples[langKey];

  // Helper: load messages for a given chat
  const loadMessages = async (chatId: string) => {
    try {
      const res = await apiGetAsaChatMessages(chatId);
      if(res.data.messages?.length > 0){
        setMessages(res.data.messages);
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    }
  };

  useEffect(() => {
    setCurrentChatId(params.chatId ? params.chatId : null);
  }, [params]);

  // Load all chats on mount
  useEffect(() => {
    const loadAll = async () => {
      try {
        const res = await apiGetAllAsaChats();
        setChats(res.data.chats);
      } catch (error) {
        console.error('Failed to load chats:', error);
      }
    };
    loadAll();
  }, []);

  // Load messages when current chat changes
  useEffect(() => {
    if (currentChatId) {
      loadMessages(currentChatId);
    } else {
      setMessages([]);
    }
  }, [currentChatId]);

  // Typing effect for title text (restart when language changes)
  useEffect(() => {
    const text = t.title;
    let index = 0;
    setTitleText('');
    const interval = setInterval(() => {
      index++;
      if (index <= text.length) {
        setTitleText(text.slice(0, index));
      } else {
        clearInterval(interval);
      }
    }, 70);
    return () => clearInterval(interval);
  }, [t.title]);

  // Create chat with first message
  const handleCreateChatWithMessage = async (firstMessage: string) => {
    if (isCreatingChat || !auth || !auth.user) return;
    
    navigate('/chat/create');

    setIsCreatingChat(true);
    setIsAssistantTyping(true);

    const userMsg: AsaChatMessage = {
      role: 'user',
      content: firstMessage,
      timestamp: serverTimestamp() as Timestamp,
    };
    setMessages([userMsg]);

    try {
      const newChat = await apiCreateAsaChat();
      const { chatId } = newChat.data;

      if (chatId) {

        setCurrentChatId(chatId);
        setChats((prev) => [
          ...prev,
          {
            id: chatId,
            userId: auth.user?.id || '',
            diagnosis: '',
            urgency: 1,
            createdAt: new Date() as any,
            updatedAt: new Date() as any,
          },
        ]);

        const response = await apiSendChat(chatId, [userMsg]);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        await streamResponse(response);

        navigate(`/chat/${chatId}`);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    } finally {
      setIsCreatingChat(false);
      setIsAssistantTyping(false);
    }
  };

  // Standard send function
  const handleSend = async () => {
    if (!input.trim()) return;

    // If no chat exists, create one.
    if (!currentChatId) {
      const inputVar = input.trim();
      setInput('');
      await handleCreateChatWithMessage(inputVar);
      return;
    }

    setIsAssistantTyping(true);

    const userMsg: AsaChatMessage = {
      role: 'user',
      content: input,
      timestamp: serverTimestamp() as Timestamp,
    };
    // Append the user's message.
    setMessages((prev) => [...prev, userMsg]);
    setInput('');

    try {
      // Call the streaming endpoint.
      const response = await apiSendChat(currentChatId, [...messages, userMsg]);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await streamResponse(response);

    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsAssistantTyping(false);
    }
      
  };

  const streamResponse = async (response: Response) => {

    try{
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let botMessageContent = '';

      // Create a placeholder for the assistant’s reply.
      const botMsg: AsaChatMessage = {
        role: 'assistant',
        content: '',
        timestamp: serverTimestamp() as Timestamp,
      };
      setMessages(prev => [...prev, botMsg]);

      // Read streamed chunks.
      if (reader) {
        setIsAssistantTyping(false)
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
      
          const decodedValue = decoder.decode(value);
          const lines = decodedValue.split('\n\n').map(line => line.replace('data: ', ""));
      
          for (const line of lines) {
            
            if (line.trim() === '[DONE]') {
              break;
            }
            
            botMessageContent += line;
      
            setMessages((prev) => {
              const updated = [...prev];
              updated[updated.length - 1] = { ...botMsg, content: botMessageContent };
              return updated;
            });
          }
        }
      }
      
      
    } catch (error) {
      console.error('Error streaming message:', error);
    } finally {
    }
  }

  /**
   * Modified prompt click handler that also uses streaming.
   */
  const handlePromptClick = async (prompt: string) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 150)}px`;
      }
    }, 0);

    await handleCreateChatWithMessage(prompt);
  };

  const handleInputResize = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 150)}px`;
    }
  };

  const handleDeleteChat = async (chatId: string) => {
    try {
      await apiDeleteAsaChat(chatId);
      setChats((prev) => prev.filter((chat) => chat.id !== chatId));
      if (currentChatId === chatId) {
        setCurrentChatId(null);
        setMessages([]);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  return (
    // Outer container (relative) with Iridescence as the full background
    <div className="relative min-h-screen">
      <Iridescence className="absolute inset-0 z-0" />
      {/* Content placed above the background */}
      <div className="relative z-10 flex min-h-screen text-white">
        {/* SIDEBAR */}
        <Sidebar
          chats={chats}
          onSelectChat={(id) => navigate(`/chat/${id}`)}
          onDeleteChat={handleDeleteChat}
          isCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />

      {/* MAIN content -> min-w-0 so it never disappears */}
      <div className="flex flex-col flex-1 min-w-0">
        <Navbar
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />

        {!currentChatId ? (
          // HERO SECTION if no chat
          <div className="flex-1 flex flex-col items-center justify-center px-4 text-center">
            <div className="flex flex-col items-center mb-8">
              <FaStethoscope className="text-6xl text-white mb-4" />
              {/* Typing effect text restored */}
              <h1
                className="text-4xl font-bold drop-shadow-sm bg-clip-text text-transparent
                  bg-gradient-to-r from-white via-fuchsia-100 to-pink-200
                  whitespace-pre-line"
              >
                {titleText || '...'}
              </h1>
            </div>

              {/* Hero form: input & send button */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSend();
                }}
                className="relative w-full max-w-2xl flex items-center
                  bg-white/10 backdrop-blur-sm rounded-full py-4 px-6
                  shadow-xl border border-white/20 hover:bg-white/20
                  transition-transform duration-200 hover:scale-105 focus-within:ring-2 focus-within:ring-white"
              >
                <textarea
                  ref={inputRef}
                  rows={1}
                  className="flex-1 bg-transparent text-white placeholder-gray-200 focus:outline-none resize-none max-h-40 overflow-hidden text-lg leading-snug"
                  placeholder={t.sendPlaceholder}
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                    handleInputResize();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                />
                <button
                  type="submit"
                  className="ml-2 bg-purple-600 hover:bg-purple-700 text-white p-3 rounded-full focus:outline-none shadow-lg transform hover:scale-110 transition-transform flex items-center justify-center"
                >
                  <FaArrowUp className="w-6 h-6" />
                </button>
              </form>

              {/* Prompt Cards */}
              <div className="mt-10 max-w-4xl w-full px-4">
                <h2 className="text-xl font-semibold mb-3">{t.promptHeading}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  {currentPrompts.map((item: { text: string; icon: JSX.Element }, idx: number) => (
                    <button
                      key={idx}
                      onClick={() => handlePromptClick(item.text)}
                      className="relative flex items-start space-x-2 bg-white/10 p-4 text-left 
                        rounded-2xl transition-all duration-300 ease-out transform-gpu
                        backdrop-blur-sm shadow-md border border-white/20 hover:bg-white/20 hover:scale-105 hover:shadow-2xl hover:rotate-1"
                    >
                      <div className="text-lg">{item.icon}</div>
                      <p className="text-base text-gray-100 leading-tight">{item.text}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            // CHAT UI when a chat is selected
            <>
              <div className="flex-1 p-6 w-full overflow-auto space-y-4">
                {messages.map((msg, index) => {
                  const isUser = msg.role === 'user';
                  const isAssistant = msg.role === 'assistant';
                  return (
                    <div key={index} className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
                      {isAssistant && (
                        <div className="flex items-center mr-3">
                          <div className="w-10 h-10 rounded-full overflow-hidden shadow-sm">
                            <img
                              src={asaProfileImage}
                              alt="Ása"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={`
                          relative px-4 py-3 max-w-xs md:max-w-md shadow-md
                          backdrop-blur-sm bg-white/20 border border-white/20
                          ${
                            isUser
                              ? 'rounded-l-3xl rounded-br-none bg-purple-600 text-white'
                              : 'rounded-r-3xl rounded-bl-none text-white'
                          }
                          mb-4
                        `}
                      >
                        {formatGptTextToJSX(msg.content)}
                      </div>
                    </div>
                  );
                })}
                {isAssistantTyping && (
                  <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-full overflow-hidden shadow-sm">
                      <img
                        src={asaProfileImage}
                        alt="Ása"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="bg-white/20 border border-white/20 px-4 py-3 rounded-r-3xl rounded-bl-none shadow-md flex items-center gap-2 animate-pulse">
                      <PulseLoader color="#ffffff" size={6} />
                    </div>
                  </div>
                )}
              </div>
              <div className="p-4 bg-transparent">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSend();
                  }}
                  className="relative w-full max-w-3xl mx-auto flex items-center
                    bg-white/10 backdrop-blur-sm rounded-full py-4 px-6 shadow-xl border border-white/20 hover:bg-white/20 transition-transform duration-200 hover:scale-105 focus-within:ring-2 focus-within:ring-white"
                >
                  <textarea
                    ref={inputRef}
                    rows={1}
                    className="flex-1 bg-transparent text-white placeholder-gray-200 focus:outline-none resize-none max-h-40 overflow-hidden text-lg leading-snug"
                    placeholder={t.sendPlaceholder}
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                      handleInputResize();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSend();
                      }
                    }}
                  />
                  <button
                    type="submit"
                    className="ml-2 bg-purple-600 hover:bg-purple-700 text-white p-3 rounded-full focus:outline-none shadow-lg transform hover:scale-110 transition-transform flex items-center justify-center"
                  >
                    <FaArrowUp className="w-6 h-6" />
                  </button>
                </form>
                <p className="text-center text-sm text-white/80 mt-2">
                  {t.emergencyText}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;

import React, { useState } from 'react';
import { LogoutBar } from '../components/LogoutBar';
import SupportModal from '../components/SupportModal';
import RotatingText from '../components/RotatingText/RotatingText';

const Header: React.FC = () => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);

  return (
    <>
      <header className="w-full bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white shadow-2xl">
        <div className="max-w-6xl mx-auto px-8 py-5 flex items-center justify-between">
          {/* Left Section: Logo, Title, and Rotating Tagline */}
          <div className="flex flex-col">
            <div className="flex items-center space-x-6">
              <img
                src="/Logo.png"
                alt="Logo"
                className="w-14 h-14 object-contain transition-transform duration-300 hover:opacity-90 hover:scale-110"
              />
              <h1 className="text-3xl font-extrabold tracking-tight transition-colors duration-300 hover:text-teal-400">
                Skjáborð
              </h1>
            </div>
            <div className="mt-2">
              <RotatingText
                texts={[
                  "Welcome to the Dashboard",
                  "Monitor Chats in Real-Time",
                  "Instant Support Available",
                ]}
                auto={true}
                rotationInterval={12000}
                mainClassName="text-lg text-purple-300 font-bold"
              />
            </div>
          </div>

          {/* Right Section: Navigation & Logout */}
          <div className="flex items-center space-x-10">
            <button
              onClick={() => setIsSupportModalOpen(true)}
              className="flex items-center space-x-2 text-xl transition-colors duration-300 text-teal-400 hover:text-teal-200 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5v1.125a3.375 3.375 0 11-6.75 0V10.5M5.25 10.5a7.5 7.5 0 1115 0v1.125a6 6 0 01-12 0V10.5"
                />
              </svg>
              <span>Aðstoð</span>
            </button>
            <a
              href="#"
              className="text-xl transition-colors duration-300 text-teal-400 hover:text-teal-200"
            >
              Stillingar
            </a>
            <LogoutBar />
          </div>
        </div>

        {/* Animated Border */}
        <div className="w-full h-1 bg-gradient-to-r from-teal-500 via-purple-500 to-blue-500 animate-pulse"></div>
      </header>

      {/* Support Modal */}
      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
      />
    </>
  );
};

export default Header;

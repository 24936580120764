import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import AccountDropdown from '../components/AccountDropdown';

interface NavbarProps {
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isSidebarCollapsed, toggleSidebar }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <nav className="bg-gradient-to-r from-gray-900 via-purple-950 to-cyan-800 animate-[gradientMoveHorizontal_10s_ease-in-out_infinite] bg-[length:200%_200%] shadow-gray-700/50 p-4 flex justify-between items-center shadow-xl border-b border-transparent shadow-inner shadow-gray-800/50">
      <div className="flex items-center px-4">
        {isSidebarCollapsed && (
          <>
            <button
              onClick={toggleSidebar}
              className="text-cyan-400 hover:bg-purple-700 p-2 rounded-md transition-transform duration-200 transform hover:scale-105 shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
            <button
              className="text-purple-300 hover:bg-cyan-600 p-2 rounded-md transition-transform duration-200 transform hover:scale-105 shadow-md"
              onClick={() => navigate('/chat')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </>
        )}
        <div onClick={() => navigate('/chat')} className="flex flex-row cursor-pointer">
          <img
            src="/logo.png"
            alt="Logo"
            className="h-10 w-10 mr-3 hover:opacity-90 transition-opacity duration-200 transform hover:scale-105"
          />
          <h1 className="text-3xl font-extrabold">
            <span className="relative inline-block">
              {/* Base Gradient Text */}
              <span className="bg-gradient-to-r from-cyan-500 via-purple-700 to-pink-600 bg-clip-text text-transparent">
                Ása 3.0 "BETA"
              </span>
              {/* Shine Overlay */}
              <span
                className="absolute inset-0 bg-[linear-gradient(120deg,transparent_40%,rgba(255,255,255,0.8)_50%,transparent_60%)] bg-[length:200%_100%] animate-shine"
                style={{ WebkitBackgroundClip: 'text' }}
              />
            </span>
          </h1>
        </div>
      </div>
      {auth && auth.user && (
        <div className="flex items-center">
          <div className="flex items-center mr-4 text-white font-semibold">
            <span>Hi ☺️ {auth.user.displayName || auth.user.fullname}</span>
            <AccountDropdown onLogout={auth.logout} />
          </div>
          {auth.isAdmin && (
            <Link
              to="/create-doctor"
              className="text-cyan-400 hover:text-pink-400 transition-colors duration-300 text-xl font-semibold"
            >
              Create Doctor
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from 'react';

export const NotFound404: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center subtle-moving-gradient relative">
      <div className="max-w-md w-full bg-opacity-80 bg-gray-900 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105">
        <div className="flex justify-center mb-6">
          <img src="/testlogo.png" alt="Logo" className="h-10 w-10 mr-3" />
        </div>
        <h2 className="text-3xl font-extrabold mb-6 text-white text-center tracking-wider">
          404 - Síða fannst ekki
        </h2>
      </div>
    </div>
  );
};

export default NotFound404;

import React from 'react';
import { RestrictedAsaChat } from '../api/asaChat';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  chats: RestrictedAsaChat[];
  onSelectChat: (chatId: string) => void;
  onDeleteChat: (chatId: string) => void;
  isCollapsed: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  chats,
  onSelectChat,
  onDeleteChat,
  isCollapsed,
  toggleSidebar,
}) => {
  const navigate = useNavigate();

  return (
    <aside
      className={`
        fixed inset-y-0 left-0  /* pinned top-left, full height */
        bg-blue-200 text-blue-900 shadow-lg z-50
        overflow-y-auto overflow-x-hidden
        transition-all duration-300 ease-in-out
        ${isCollapsed ? 'w-0' : 'w-64'} /* collapsed => width 0, expanded => 64 */
      `}
    >
      {/* Header with toggles */}
      <div className="flex items-center justify-between p-4">
        <button
          onClick={toggleSidebar}
          className="text-blue-900 hover:bg-blue-300 p-2 rounded-full transition-colors duration-300"
        >
          {isCollapsed ? (
            /* Right Arrow Icon */
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          ) : (
            /* Left Arrow Icon */
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          )}
        </button>

        {!isCollapsed && (
          <button
            className="text-blue-900 hover:bg-blue-300 p-2 rounded-full transition-colors duration-300"
            onClick={() => navigate('/chat')}
          >
            {/* Pencil Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 0 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931ZM16.862 4.487 19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        )}
      </div>

      {/* Chat List */}
      {!isCollapsed && (
        <nav className="p-4 flex-grow">
          <h2 className="text-sm font-semibold text-gray-600 mb-4">Nýleg spjöll</h2>
          <div className="flex flex-col gap-2">
            {chats.map((chat, index) => (
              <div
                key={index}
                className="flex items-center justify-between text-left hover:bg-blue-300 p-2 rounded transition-colors group"
                onClick={() => onSelectChat(chat.id)}
              >
                <button className="text-blue-900 flex-grow text-left">{chat.id}</button>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // so click doesn't also select chat
                    onDeleteChat(chat.id);
                  }}
                  className="text-blue-900 hover:text-red-600 p-1 rounded-full transition-colors opacity-0 group-hover:opacity-100"
                >
                  {/* Trash Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 1 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </nav>
      )}
    </aside>
  );
};

export default Sidebar;

// src/components/DocSidebar.tsx
import { LogoutBar } from '../components/LogoutBar';
import React from 'react';
import {
  FaUsers,
  FaUserPlus,
  FaUserMd,
  FaCheckCircle,
  FaSignOutAlt,
} from 'react-icons/fa';


interface DocSidebarProps {
  filter: 'all' | 'new' | 'personal' | 'reviewed';
  setFilter: (value: 'all' | 'new' | 'personal' | 'reviewed') => void;
}

const DocSidebar: React.FC<DocSidebarProps> = ({ filter, setFilter }) => {
  const sidebarItems = [
    { key: 'all', icon: <FaUsers size={18} />, label: 'All' },
    { key: 'new', icon: <FaUserPlus size={18} />, label: 'New' },
    { key: 'personal', icon: <FaUserMd size={18} />, label: 'Personal' },
    { key: 'reviewed', icon: <FaCheckCircle size={18} />, label: 'Reviewed' },
  ] as const;

  return (
    <aside
      className="
        sticky top-0
        h-screen
        w-20 md:w-24
        bg-indigo-900 text-white
        flex flex-col
        items-center
        py-8
        shadow-lg
      "
    >
      {/* Filter Buttons */}
      <div className="flex flex-col space-y-6">
        {sidebarItems.map((item) => {
          const active = filter === item.key;
          return (
            <button
              key={item.key}
              onClick={() => setFilter(item.key)}
              className={`
                flex flex-col items-center justify-center
                space-y-1
                transform transition-all duration-300
                ${
                  active
                    ? 'scale-110 text-pink-200'
                    : 'opacity-80 hover:scale-105'
                }
              `}
            >
              <div
                className={`
                  w-10 h-10 rounded-full flex items-center justify-center
                  ${
                    active
                      ? 'bg-pink-600'
                      : 'bg-white/10 hover:bg-white/20'
                  }
                `}
              >
                {item.icon}
              </div>
              <span
                className={`
                  hidden md:block text-xs font-semibold 
                  ${active ? 'text-white' : 'text-gray-200'}
                `}
              >
                {item.label}
              </span>
            </button>
          );
        })}
      </div>

      {/* Logout Button at Bottom */}
      <div className="mt-auto mb-4">
        <button
          className="
            flex flex-col items-center justify-center
            transform transition-all duration-300
            opacity-80 hover:scale-105
            text-red-200
          "
        >
          <div
            className="
   
              hover:bg-white/20 rounded-full
            "
          >
            <LogoutBar/>
          </div>
          <span className="hidden md:block text-xs font-semibold text-white">
            Logout
          </span>
        </button>
        
      </div>
    </aside>
  );
};

export default DocSidebar;

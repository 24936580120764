import React, {
  useState,
  Children,
  useRef,
  useLayoutEffect,
  HTMLAttributes,
  ReactNode,
  ElementType,
} from "react";
import { motion, AnimatePresence, Variants } from "framer-motion";

interface StepperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  initialStep?: number;
  onStepChange?: (step: number) => void;
  onFinalStepCompleted?: () => void;
  stepCircleContainerClassName?: string;
  stepContainerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  backButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  nextButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  backButtonText?: string;
  nextButtonText?: string;
  disableStepIndicators?: boolean;
  renderStepIndicator?: (props: {
    step: number;
    currentStep: number;
    onStepClick: (clicked: number) => void;
  }) => ReactNode;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export default function Stepper({
  children,
  onStepChange = () => {},
  onFinalStepCompleted = () => {},
  stepCircleContainerClassName = "",
  stepContainerClassName = "",
  contentClassName = "",
  footerClassName = "",
  backButtonProps = {},
  nextButtonProps = {},
  backButtonText = "Back",
  nextButtonText = "Continue",
  disableStepIndicators = false,
  renderStepIndicator,
  currentStep,
  setCurrentStep,
  ...rest
}: StepperProps) {
  
  const [direction, setDirection] = useState<number>(0);
  const stepsArray = Children.toArray(children);
  const totalSteps = stepsArray.length;
  const isCompleted = currentStep > totalSteps;
  const isLastStep = currentStep === totalSteps;

  const updateStep = (newStep: number) => {
    setCurrentStep(newStep);
    if (newStep > totalSteps) {
      onFinalStepCompleted();
    } else {
      onStepChange(newStep);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setDirection(-1);
      updateStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (!isLastStep) {
      setDirection(1);
      updateStep(currentStep + 1);
    }
  };

  const handleComplete = () => {
    setDirection(1);
    updateStep(totalSteps + 1);
  };

  return (
    <div
      {...rest}
      className={`rounded-4xl bg-transparent p-4 ${stepCircleContainerClassName}`}
    >
      {/* Step Indicators */}
      <div
        className={`flex items-center justify-center mb-6 ${stepContainerClassName} rounded-2xl`}
      >
        {stepsArray.map((_, index) => {
          const stepNumber = index + 1;
          const isNotLastStep = index < totalSteps - 1;

          return (
            <React.Fragment key={stepNumber}>
              {renderStepIndicator ? (
                renderStepIndicator({
                  step: stepNumber,
                  currentStep,
                  onStepClick: (clicked) => {
                    setDirection(clicked > currentStep ? 1 : -1);
                    updateStep(clicked);
                  },
                })
              ) : (
                <StepIndicator
                  step={stepNumber}
                  currentStep={currentStep}
                  onClickStep={(clicked) => {
                    setDirection(clicked > currentStep ? 1 : -1);
                    updateStep(clicked);
                  }}
                  disableStepIndicators={disableStepIndicators}
                />
              )}
              {isNotLastStep && (
                <StepConnector isComplete={currentStep > stepNumber} />
              )}
            </React.Fragment>
          );
        })}
      </div>

      {/* Step Content */}
      <StepContentWrapper
        isCompleted={isCompleted}
        currentStep={currentStep}
        direction={direction}
        className={`relative rounded-xl bg-transparent p-4 ${contentClassName}`}
      >
        {stepsArray[currentStep - 1]}
      </StepContentWrapper>

      {/* Footer Buttons */}
      {!isCompleted && (
        <div className={`mt-4 flex items-center justify-between ${footerClassName}`}>
          {/* Back Button */}
          {currentStep > 1 ? (
            <button
              onClick={handleBack}
              className={`px-5 py-2 rounded-full bg-purple-300 text-white 
                          hover:bg-purple-400 transition-all shadow-md 
                          ${backButtonProps.className || ""}`}
              {...backButtonProps}
            >
              {backButtonText}
            </button>
          ) : (
            <div />
          )}

          {/* Next / Complete Button */}
          <button
            onClick={isLastStep ? handleComplete : handleNext}
            className={`px-5 py-2 rounded-full bg-purple-500 text-white 
                        hover:bg-purple-600 transition-all shadow-md 
                        ${nextButtonProps.className || ""}`}
            {...nextButtonProps}
          >
            {isLastStep ? "Complete" : nextButtonText}
          </button>
        </div>
      )}
    </div>
  );
}

/* ---------------------------------- */
/*         StepContentWrapper         */
/* ---------------------------------- */

interface StepContentWrapperProps {
  isCompleted: boolean;
  currentStep: number;
  direction: number;
  children: ReactNode;
  className?: string;
}

function StepContentWrapper({
  isCompleted,
  currentStep,
  direction,
  children,
  className = "",
}: StepContentWrapperProps) {
  const [parentHeight, setParentHeight] = useState<number>(0);
  const AnimatePresenceFixed = AnimatePresence as ElementType;

  return (
    <motion.div
      style={{ position: "relative", overflow: "hidden" }}
      animate={{ height: isCompleted ? 0 : parentHeight }}
      transition={{
        type: "spring",
        duration: 0.45,
        damping: 25,
        stiffness: 200,
      }}
      className={className}
    >
      <AnimatePresenceFixed initial={false} mode="sync" custom={direction}>
        {!isCompleted && (
          <SlideTransition
            key={currentStep}
            direction={direction}
            onHeightReady={(h) => setParentHeight(h)}
          >
            {children}
          </SlideTransition>
        )}
      </AnimatePresenceFixed>
    </motion.div>
  );
}

/* ---------------------------------- */
/*          Slide Transition          */
/* ---------------------------------- */

interface SlideTransitionProps {
  children: ReactNode;
  direction: number;
  onHeightReady: (height: number) => void;
}

function SlideTransition({
  children,
  direction,
  onHeightReady,
}: SlideTransitionProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      onHeightReady(containerRef.current.offsetHeight);
    }
  }, [children, onHeightReady]);

  return (
    <motion.div
      ref={containerRef}
      custom={direction}
      variants={stepVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: 0.45 }}
      style={{ position: "absolute", width: "100%", top: 0, left: 0 }}
    >
      {children}
    </motion.div>
  );
}

const stepVariants: Variants = {
  enter: (dir: number) => ({
    x: dir >= 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  center: {
    x: "0%",
    opacity: 1,
  },
  exit: (dir: number) => ({
    x: dir >= 0 ? "-50%" : "50%",
    opacity: 0,
  }),
};

/* ---------------------------------- */
/*               <Step>               */
/* ---------------------------------- */

interface StepProps {
  children: ReactNode;
}

export function Step({ children }: StepProps) {
  return <div>{children}</div>;
}

/* ---------------------------------- */
/*         Step Indicator Dot         */
/* ---------------------------------- */

interface StepIndicatorProps {
  step: number;
  currentStep: number;
  onClickStep: (clicked: number) => void;
  disableStepIndicators?: boolean;
}

function StepIndicator({
  step,
  currentStep,
  onClickStep,
  disableStepIndicators = false,
}: StepIndicatorProps) {
  const status =
    currentStep === step
      ? "active"
      : currentStep < step
      ? "inactive"
      : "complete";

  const handleClick = () => {
    if (!disableStepIndicators && step !== currentStep) {
      onClickStep(step);
    }
  };

  return (
    <motion.button
      onClick={handleClick}
      className="relative outline-none focus:outline-none mx-2"
      animate={status}
      initial={false}
      style={{ cursor: disableStepIndicators ? "default" : "pointer" }}
    >
      <motion.div
        variants={{
          inactive: {
            scale: 1,
            backgroundColor: "#d1d5db", // gray-300
            borderColor: "#d1d5db",
          },
          active: {
            scale: 1.15,
            backgroundColor: "#8b5cf6", // purple-500
            borderColor: "#8b5cf6",
          },
          complete: {
            scale: 1.15,
            backgroundColor: "#ec4899", // pink-500
            borderColor: "#ec4899",
          },
        }}
        transition={{ duration: 0.3 }}
        className="flex h-10 w-10 items-center justify-center rounded-full border-2 font-semibold text-white shadow-md"
      >
        {status === "complete" ? (
          <CheckIcon className="h-5 w-5" />
        ) : (
          <span className="text-sm leading-none">{step}</span>
        )}
      </motion.div>
    </motion.button>
  );
}

/* ---------------------------------- */
/*         Step Connector Bar         */
/* ---------------------------------- */

interface StepConnectorProps {
  isComplete: boolean;
}

function StepConnector({ isComplete }: StepConnectorProps) {
  const lineVariants: Variants = {
    incomplete: { width: 0, backgroundColor: "#a78bfa" }, // purple-300
    complete: { width: "100%", backgroundColor: "#a855f7" }, // purple-500
  };

  return (
    <div className="relative flex-1 h-1 rounded-full overflow-hidden">
      <motion.div
        className="absolute left-0 top-0 h-full"
        variants={lineVariants}
        initial={false}
        animate={isComplete ? "complete" : "incomplete"}
        transition={{ duration: 0.4 }}
      />
    </div>
  );
}

/* ---------------------------------- */
/*              CheckIcon             */
/* ---------------------------------- */

interface CheckIconProps extends React.SVGProps<SVGSVGElement> {}

function CheckIcon(props: CheckIconProps) {
  return (
    <svg
      {...props}
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      viewBox="0 0 24 24"
    >
      <motion.path
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          delay: 0.1,
          type: "tween",
          ease: "easeOut",
          duration: 0.3,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}

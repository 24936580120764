import {Axios, AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index";
import { Timestamp } from 'firebase/firestore';

export type ChatResponse = Response

export type CreateAsaChatResponse = AxiosResponse<{chatId: string}>

export type CreateAsaChatWithMessageResponse = AxiosResponse<{chatId: string, message: string}>

export type DeleteAsaChatResponse = AxiosResponse<{success: boolean}>

export interface RestrictedAsaChat {
    id: string,
    createdAt: number,
    updatedAt: number
}

export interface NurseRestrictedAsaChat extends RestrictedAsaChat {
    diagnosis: string,
    urgency: number,
    dismissed: boolean
}

export interface DoctorRestrictedAsaChat extends RestrictedAsaChat {
    diagnosis: string,
    urgency: number,
    dismissed: boolean,
    userId: string,
    user: {
        id: string,
        email: string,
        fullname: string,
        role: string
        ssn: string,
    }
}

export type ChatListResponse = AxiosResponse<{chats: RestrictedAsaChat[]}>

export interface AsaChatMessage {
    content: string,
    role: "user" | "assistant",
    timestamp: Timestamp
}
export type AsaChatMessagesResponse = AxiosResponse<{messages: AsaChatMessage[]}>


export interface AsaChat {
    id: string,
    userId: string,
    diagnosis: string,
    urgency: number,
    dismissed: boolean,
    createdAt: number,
    updatedAt: number,
    user: {
        id: string,
        email: string,
        fullname: string,
        role: string
        ssn: string,
    }
}
export type AsaChatForNurseResponse = AxiosResponse<{chats: NurseRestrictedAsaChat[], nextPage: string}>

export type AsaChatForDoctorResponse = AxiosResponse<{chats: DoctorRestrictedAsaChat[], nextPage: string}>

export type AsaMarkChatAsDismissedResponse = AxiosResponse<{success: boolean}>

export type AsaSendChatToDoctorResponse = AxiosResponse<{success: boolean}>



export const apiSendChat = async (chatId: string, chat: { role: 'user' | 'assistant'; content: string }[]): Promise<ChatResponse> => {
    const headers = {
        'Content-Type': 'application/json',
        ...(await getAuthorization()),
    };

    const isDev = parseInt(process.env?.REACT_APP_DEV ?? "0") == 1;
    const baseUrl = isDev ? process.env.REACT_APP_DEV_API_BASE_URL : "";

    return await fetch(baseUrl + `/api/asa/chat/${chatId}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ chat }),
    });
};

export const apiCreateAsaChat = async (): Promise<CreateAsaChatResponse> => 
    await api.post('/api/asa/chat/new', {}, { headers: await getAuthorization() });

export const apiCreateAsaChatWithMessage = async (message: string): Promise<CreateAsaChatWithMessageResponse> =>
    await api.post('/api/asa/chat/newWithMessage', { message }, { headers: await getAuthorization() });

export const apiDeleteAsaChat = async (chatId: string): Promise<DeleteAsaChatResponse> =>
    await api.delete(`/api/asa/chat/${chatId}`, { headers: await getAuthorization() });

export const apiGetAllAsaChats = async (): Promise<ChatListResponse> => 
    await api.get('/api/asa/chats', { headers: (await getAuthorization()) });


export const apiGetAsaChatMessages = async (chatId: string): Promise<AsaChatMessagesResponse> => 
    await api.get(`/api/asa/chat/${chatId}/messages`, { headers: (await getAuthorization()) });

export interface AsaChatsFilter {
    urgency_from?: number,
    urgency_to?: number,
    created_from?: number,
    created_to?: number,
    updated_from?: number
    updated_to?: number,
    limit?: number,
    read?: boolean,
    ssn?: string,
    startAfter?: string,
    sortBy?: "newest" | "oldest"
}

export const apiGetAsaChatsForNurse = async (filter: AsaChatsFilter): Promise<AsaChatForNurseResponse> => {
    return await api.get('/api/asa/doctor/chats', {
        params: {
            filter,
        },
        headers: await getAuthorization(),
    });
};

export const apiGetAsaChatsForDoctor = async (filter: AsaChatsFilter): Promise<AsaChatForDoctorResponse> => {
    return await api.get('/api/asa/doctor/chats', {
        params: {
            filter,
        },
        headers: await getAuthorization(),
    });
};


export const apiMarkChatAsDismissed = async (chatId: string): Promise<AsaMarkChatAsDismissedResponse> =>
    await api.post(`/api/asa/chat/${chatId}/read`, {}, { headers: await getAuthorization() });

export const apiSendChatToDoctor = async (chatId: string): Promise<AsaSendChatToDoctorResponse> =>
    await api.post(`/api/asa/chat/${chatId}/sendToDoctor`, {}, { headers: await getAuthorization() });
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { apiCreateDoctor } from '../api/doctor';
import { Company } from '../api/auth';
import { apiGetCompanyList } from '../api/company';

// Replace with your actual logo path
const logoSrc = '/testlogo.png';

const CreateDoctor: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullname, setFullname] = useState('');
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const fetchCompanies = async () => {
    try {
      const res = await apiGetCompanyList();
      setCompanies(res.data.companies);
    } catch (error) {
      alert('Error loading companies');
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!selectedCompanyId) {
        toast.error("Vinsamlegast veldu fyrirtæki.", { position: "top-center" });
        return;
      }

      const { data } = await apiCreateDoctor(email, password, fullname, selectedCompanyId);

      if (data.success) {
        navigate('/doctor-list');
        toast.success(data.message, { position: "top-center" });
      } else {
        toast.error(data.message, { position: "top-center" });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.", { position: "top-center" });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-300 via-purple-300 to-pink-200 flex flex-col items-center justify-center p-6">
      {/* Smaller Logo Frame Positioned in the Top-Left Corner */}
      <div className="fixed top-6 left-6">
        <img
          src={logoSrc}
          alt="Logo"
          className="h-12 w-12 rounded-full"
        />
      </div>



      <div className="max-w-3xl w-full bg-white bg-opacity-70 rounded-3xl shadow-xl p-8 backdrop-filter backdrop-blur-lg border border-opacity-20 border-white">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-4xl font-extrabold text-gray-900">Skapa lækni</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate('/doctor-list')}
              className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-5 py-2 rounded-full shadow-md hover:from-blue-600 hover:to-blue-700 transition-all transform hover:scale-105"
            >
              Til baka
            </button>
            <button
              onClick={context?.logout ?? (() => { })}
              className="bg-gradient-to-r from-red-500 to-red-600 text-white p-3 rounded-full shadow-md hover:from-red-600 hover:to-red-700 transition-all transform hover:scale-105"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
              </svg>

            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium">Select Company</label>
            <select
              className="w-full px-4 py-3 mt-2 bg-white bg-opacity-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setSelectedCompanyId(e.target.value)}
              required
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Fullt nafn</label>
            <input
              type="text"
              className="w-full px-4 py-3 mt-2 bg-white bg-opacity-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Netfang</label>
            <input
              type="email"
              className="w-full px-4 py-3 mt-2 bg-white bg-opacity-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Lykilorð</label>
            <input
              type="password"
              className="w-full px-4 py-3 mt-2 bg-white bg-opacity-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-500 to-indigo-500 text-white py-3 rounded-full shadow-lg hover:from-blue-600 hover:to-indigo-600 transition-all transform hover:scale-105"
          >
            Skapa lækni
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateDoctor;

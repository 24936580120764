import React, { useState, useEffect, useContext } from 'react';
import { FaHospital, FaCheckCircle } from 'react-icons/fa';
import DocSidebar from '../components/DocSidebar';
// Import all ASA chat API calls from your API file.
// Adjust the import path as needed.
import {
  apiGetAsaChatsForDoctor,
  apiMarkChatAsDismissed,
} from '../api/asaChat';
import { AuthContext } from '../contexts/AuthContext';

/* ============= CheckmarkPop for the "Mark Reviewed" animation ============= */
function CheckmarkPop({ patientId }: { patientId: string }) {
  return (
    <div
      className="
        absolute
        bottom-12 right-6
        text-green-600
        animate-checkPop
        pointer-events-none
      "
    >
      <FaCheckCircle className="text-3xl" />
    </div>
  );
}

/* ============= New Patients Section ============= */
function NewPatientsSection({
  patients,
  expandedPatients,
  toggleExpanded,
  handleMarkReviewed,
  reviewCheckId,
}: {
  patients: Patient[];
  expandedPatients: { [key: string]: boolean };
  toggleExpanded: (id: string) => void;
  handleMarkReviewed: (id: string) => void;
  reviewCheckId: string | null;
}) {
  if (patients.length === 0) return null;

  return (
    <section
      className="
        p-8 rounded-xl shadow-xl border-l-8 border-green-500 
        bg-gradient-to-r from-green-50 to-white
        transition-transform duration-300 hover:scale-[1.01] hover:shadow-2xl
        mb-10
      "
    >
      <h2 className="text-3xl md:text-4xl font-bold text-gray-700 mb-6">
        New Clients
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {patients.map((patient) => (
          <div
            key={patient.id}
            className="
              relative
              bg-white bg-opacity-90
              rounded-xl shadow-lg p-6
              flex flex-col justify-between
              hover:shadow-2xl transition-shadow
            "
          >
            <div>
              <h3 className="text-2xl font-bold mb-2 text-gray-700">
                {patient.name}
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                Patient ID: {patient.id}
              </p>
              <p className="text-gray-600">
                {expandedPatients[patient.id]
                  ? patient.details
                  : `${patient.details.slice(0, 60)}...`}
              </p>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <button
                onClick={() => toggleExpanded(patient.id)}
                className="text-indigo-600 font-medium hover:underline"
              >
                {expandedPatients[patient.id] ? 'Show Less' : 'Show More'}
              </button>
              <button
                onClick={() => handleMarkReviewed(patient.id)}
                className="
                  bg-gradient-to-r from-pink-500 to-purple-600
                  text-white px-4 py-2 rounded-full shadow-md
                  font-semibold transform hover:scale-105
                "
              >
                Mark Reviewed
              </button>
            </div>
            {reviewCheckId === patient.id && (
              <CheckmarkPop patientId={patient.id} />
            )}
            <span className="absolute top-4 right-4 bg-green-500 text-white px-3 py-1 rounded-full text-xs font-bold">
              NEW
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}

/* ============= Personal Patients Section ============= */
function PersonalPatientsSection({
  patients,
  expandedPatients,
  toggleExpanded,
  handleMarkReviewed,
  reviewCheckId,
}: {
  patients: Patient[];
  expandedPatients: { [key: string]: boolean };
  toggleExpanded: (id: string) => void;
  handleMarkReviewed: (id: string) => void;
  reviewCheckId: string | null;
}) {
  if (patients.length === 0) return null;

  return (
    <section
      className="
        p-8 rounded-xl shadow-xl border-l-8 border-indigo-500
        bg-gradient-to-r from-indigo-50 to-blue-50
        transition-transform duration-300 hover:scale-[1.01] hover:shadow-2xl
        mb-10
      "
    >
      <h2 className="text-3xl md:text-4xl font-bold text-gray-700 mb-6">
        Your Personal Patients
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {patients.map((patient) => (
          <div
            key={patient.id}
            className="
              relative
              bg-white bg-opacity-90
              rounded-xl shadow-lg p-6
              flex flex-col justify-between
              hover:shadow-2xl transition-shadow
            "
          >
            <div>
              <h3 className="text-2xl font-bold mb-2 text-gray-700">
                {patient.name}
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                Patient ID: {patient.id}
              </p>
              <p className="text-gray-600">
                {expandedPatients[patient.id]
                  ? patient.details
                  : `${patient.details.slice(0, 60)}...`}
              </p>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <button
                onClick={() => toggleExpanded(patient.id)}
                className="text-indigo-600 font-medium hover:underline"
              >
                {expandedPatients[patient.id] ? 'Show Less' : 'Show More'}
              </button>
              {!patient.reviewed && (
                <button
                  onClick={() => handleMarkReviewed(patient.id)}
                  className="
                    bg-gradient-to-r from-pink-500 to-purple-600
                    text-white px-4 py-2 rounded-full shadow-md
                    font-semibold transform hover:scale-105
                  "
                >
                  Mark Reviewed
                </button>
              )}
            </div>
            {reviewCheckId === patient.id && (
              <CheckmarkPop patientId={patient.id} />
            )}
            {!patient.reviewed && (
              <span className="absolute top-4 right-4 bg-green-500 text-white px-3 py-1 rounded-full text-xs font-bold">
                NEW
              </span>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

/* ============= Reviewed Patients Section ============= */
function ReviewedPatientsSection({
  patients,
  expandedPatients,
  toggleExpanded,
}: {
  patients: Patient[];
  expandedPatients: { [key: string]: boolean };
  toggleExpanded: (id: string) => void;
}) {
  if (patients.length === 0) return null;

  return (
    <section
      className="
        p-8 rounded-xl shadow-xl border-l-8 border-gray-500
        bg-gradient-to-r from-gray-50 to-white
        transition-transform duration-300 hover:scale-[1.01] hover:shadow-2xl
        mb-10
      "
    >
      <h2 className="text-3xl md:text-4xl font-bold text-gray-700 mb-6">
        Reviewed Patients
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {patients.map((patient) => (
          <div
            key={patient.id}
            className="
              relative
              bg-white bg-opacity-80
              rounded-xl shadow-md p-6
              hover:shadow-xl transition-shadow
            "
          >
            <h3 className="text-2xl font-bold mb-2 text-gray-700">
              {patient.name}
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              Patient ID: {patient.id}
            </p>
            <p className="text-gray-600">
              {expandedPatients[patient.id]
                ? patient.details
                : `${patient.details.slice(0, 60)}...`}
            </p>
            <div className="mt-4 flex items-center justify-between">
              <button
                onClick={() => toggleExpanded(patient.id)}
                className="text-indigo-600 font-medium hover:underline"
              >
                {expandedPatients[patient.id] ? 'Show Less' : 'Show More'}
              </button>
            </div>
            <span className="absolute top-4 right-4 bg-gray-400 bg-opacity-80 text-white px-3 py-1 rounded-full text-xs font-bold">
              Reviewed
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}

/* ============= The Main DoctorView Component ============= */
interface Patient {
  id: string;
  name: string;
  details: string;
  reviewed: boolean;
  isPersonalGP?: boolean;
}

const DoctorView: React.FC = () => {
  // Pull user data from AuthContext
  const authContext = useContext(AuthContext);

  // Use authContext.user to populate doctor info.
  // Adjust the property names below to match your User schema.
  const doctor = authContext?.user
    ? {
        name: authContext.user.fullname,
        specialization:'General Practitioner',
        clinic: authContext.user.company?.name || 'Default Clinic',
        avatar: '/default-avatar.png',
      }
    : {
        name: 'Loading...',
        specialization: '',
        clinic: '',
        location: '',
        avatar: '/default-avatar.png',
      };

  // State to hold our patient data (mapped from ASA chats)
  const [patients, setPatients] = useState<Patient[]>([]);
  // Track which patients are expanded
  const [expandedPatients, setExpandedPatients] = useState<{ [key: string]: boolean }>({});
  // The filter for the 3 sections: 'all' | 'new' | 'personal' | 'reviewed'
  const [filter, setFilter] = useState<'all' | 'new' | 'personal' | 'reviewed'>('all');
  // Which patient just got the checkmark pop animation
  const [reviewCheckId, setReviewCheckId] = useState<string | null>(null);

  // Fetch ASA chats for doctor when the component mounts or when auth loading changes
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await apiGetAsaChatsForDoctor({});
        // Map each ASA chat to our Patient type.
        // For demo purposes, we're arbitrarily marking every other chat as "personal"
        const mappedPatients = response.data.chats.map((chat, index) => ({
          id: chat.id,
          name: chat.user.fullname,
          details: `Diagnosis: ${chat.diagnosis}. Urgency: ${chat.urgency}.`,
          reviewed: chat.dismissed,
          isPersonalGP: index % 2 === 0, // Arbitrary demo logic for personal patients
        }));
        setPatients(mappedPatients);
      } catch (error) {
        console.error("Error fetching ASA chats:", error);
      }
    };

    // Only fetch chats once user info is loaded
    if (!authContext?.userLoading) {
      fetchChats();
    }
  }, [authContext?.userLoading]);

  // Expand/collapse logic
  const toggleExpanded = (patientId: string) => {
    setExpandedPatients((prev) => ({
      ...prev,
      [patientId]: !prev[patientId],
    }));
  };

  // Mark as reviewed: update local state and call the API to mark the chat as dismissed
  const handleMarkReviewed = async (patientId: string) => {
    // Update local state immediately for responsiveness
    setPatients((prev) =>
      prev.map((p) =>
        p.id === patientId ? { ...p, reviewed: true } : p
      )
    );
    // Trigger the checkmark pop animation
    setReviewCheckId(patientId);
    try {
      await apiMarkChatAsDismissed(patientId);
    } catch (error) {
      console.error("Failed to mark chat as dismissed", error);
    }
    setTimeout(() => {
      setReviewCheckId(null);
    }, 1500);
  };

  // Filter logic: split patients into different sections
  const personalPatients = patients.filter((p) => p.isPersonalGP);
  const newPatients = patients.filter((p) => !p.isPersonalGP && !p.reviewed);
  const reviewedPatients = patients.filter((p) => !p.isPersonalGP && p.reviewed);

  // Decide which sections to render based on the current filter
  const showNewSection = filter === 'all' || filter === 'new';
  const showPersonalSection = filter === 'all' || filter === 'personal';
  const showReviewedSection = filter === 'all' || filter === 'reviewed';

  return (
    <div className="flex h-screen overflow-hidden">
      {/* 1) The Sticky Sidebar */}
      <DocSidebar filter={filter} setFilter={setFilter} />

      {/* 2) The main content area (scrollable) */}
      <div className="flex-1 flex flex-col overflow-y-auto">
        {/* Header */}
        <header className="relative w-full bg-gradient-to-r from-indigo-900 via-purple-700 to-pink-600 text-white py-8 px-6 shadow-lg">
          <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-sm" />
          <div className="relative z-10 flex items-center justify-between">
            {/* Title/Logo */}
            <div className="flex items-center space-x-4">
              <img
                src="/testlogo.png"
                alt="Logo"
                className="w-16 h-16 object-contain rounded-full border-4 border-white shadow-lg"
              />
              <div>
                <h1 className="text-3xl md:text-4xl font-extrabold tracking-tight mb-1 drop-shadow-lg">
                  Doctor's Dashboard
                </h1>
                <p className="opacity-90 text-sm md:text-base">
                  Empowering you with AI-assisted patient management
                </p>
              </div>
            </div>

            {/* Doctor Info from AuthContext */}
            <div
              className="
                flex flex-col md:flex-row md:items-center 
                space-y-2 md:space-y-0 md:space-x-3
                bg-white bg-opacity-20 
                backdrop-blur-sm
                rounded-lg p-2
                hover:bg-opacity-30
                transition
              "
            >
              <img
                src={doctor.avatar}
                alt="Doctor's Avatar"
                className="w-10 h-10 md:w-12 md:h-12 rounded-full border-2 border-white object-cover shadow"
              />
              <div className="flex flex-col items-start text-sm md:text-base">
                <span className="font-semibold text-white">
                  {doctor.name}
                </span>
                <span className="text-white/90">
                  {doctor.specialization}
                </span>
                <span className="mt-1 text-white/80">
                  {doctor.clinic}
                </span>
                <span className="text-white/80">
                  {doctor.location}
                </span>
              </div>
            </div>
          </div>
        </header>

        {/* Main content sections */}
        <main className="w-full px-4 md:px-8 py-10">
          {showNewSection && (
            <NewPatientsSection
              patients={newPatients}
              expandedPatients={expandedPatients}
              toggleExpanded={toggleExpanded}
              handleMarkReviewed={handleMarkReviewed}
              reviewCheckId={reviewCheckId}
            />
          )}
          {showPersonalSection && (
            <PersonalPatientsSection
              patients={personalPatients}
              expandedPatients={expandedPatients}
              toggleExpanded={toggleExpanded}
              handleMarkReviewed={handleMarkReviewed}
              reviewCheckId={reviewCheckId}
            />
          )}
          {showReviewedSection && (
            <ReviewedPatientsSection
              patients={reviewedPatients}
              expandedPatients={expandedPatients}
              toggleExpanded={toggleExpanded}
            />
          )}

          {/* Display "No patients found" if the filter yields no results */}
          {filter !== 'all' &&
            ((filter === 'new' && newPatients.length === 0) ||
              (filter === 'personal' && personalPatients.length === 0) ||
              (filter === 'reviewed' && reviewedPatients.length === 0)) && (
              <div className="mt-10 text-center text-gray-500 text-xl">
                No patients found.
              </div>
            )}

          {/* If filter=all but truly no patients exist in any category */}
          {filter === 'all' &&
            newPatients.length === 0 &&
            personalPatients.length === 0 &&
            reviewedPatients.length === 0 && (
              <p className="text-center text-gray-400 text-xl mt-16">
                No patients found.
              </p>
            )}
        </main>
      </div>
    </div>
  );
};

export default DoctorView;

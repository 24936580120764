import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useContext(AuthContext);
  const { companySlug } = useParams();

  if (!auth || !auth.user || !auth.isAdmin) {
    return companySlug ?
      <Navigate to={`/${companySlug}/login`} replace />
      : <Navigate to="/login" replace />;
  }

  return children;
};

export default AdminRoute;
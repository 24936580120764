import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Chat from './pages/Chat';
import DoctorList from './pages/DoctorsList';
import CreateDoctor from './pages/CreateDoctor';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import ProtectedRoute from './components/security/ProtectedRoute';
import AdminRoute from './components/security/AdminRoute';
import DoctorRoute from './components/security/DoctorRoute';
import ViewChats from './pages/ViewChats';
import ViewChat from './pages/ViewChat';
import UrgentChatsList from './pages/UrgentChatsList';
import ProfileView from './components/ProfileView';
import PrescriptionsView from './components/PrescriptionsView';
import HistoryView from './components/HistoryView';
import CertificatesView from './components/CertificatesView';
import DoctorsView from './components/DoctorsView';
import VaccinationsView from './components/VaccinationsView';
import DoctorView from './pages/DoctorUIview';

// NEW IMPORT for Terms & Conditions
import TermsAndConditions from './pages/TermsAndConditions';
import SuperAdminView from './pages/SuperAdminView';
import CreateClinicDirector from './components/CreateClinicDirector';
import Downtime from './pages/Downtime';
import NotFound404 from './pages/404';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <LanguageProvider>
          <Routes>
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/404" element={<NotFound404 />} />

            <Route path="/login" element={<Login />} />
            <Route path="/:companySlug/login" element={<Login />} />
            <Route path="/:companySlug/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route
              path="home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            <Route
              path="chat/:chatId?"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />

            {/* Uncomment this route if needed in the future */}
            {/*
            <Route
              path="view-chats"
              element={
                <ProtectedRoute>
                  <ViewChats />
                </ProtectedRoute>
              }
            />
            */}

            <Route
              path="view-urgent-chats"
              element={
                <ProtectedRoute allowedRoles={['nurse', 'doctor', 'admin']}>
                  <UrgentChatsList />
                </ProtectedRoute>
              }
            />

            <Route
              path="create-doctor"
              element={
                <AdminRoute>
                  <CreateDoctor />
                </AdminRoute>
              }
            />

            <Route
              path="doctor-list"
              element={
                <AdminRoute>
                  <DoctorList />
                </AdminRoute>
              }
            />

            {/* Profile Route */}
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <ProfileView />
                </ProtectedRoute>
              }
            />

            {/* New Routes for Dropdown Menu Pages */}
            <Route
              path="/prescriptions"
              element={
                <ProtectedRoute>
                  <PrescriptionsView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/history"
              element={
                <ProtectedRoute>
                  <HistoryView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/certificates"
              element={
                <ProtectedRoute>
                  <CertificatesView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctors"
              element={
                <ProtectedRoute>
                  <DoctorsView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaccinations"
              element={
                <ProtectedRoute>
                  <VaccinationsView />
                </ProtectedRoute>
              }
              />
            <Route
              path="/doctor-ui"
              element={
                <DoctorRoute>
                  <DoctorView />
                </DoctorRoute>
              }
            />

            {/* Catch-all route for invalid paths */}
            <Route path="*" element={<Login />} />
          </Routes>
        </LanguageProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;

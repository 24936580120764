// Register.tsx
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext, LanguageContextType } from '../contexts/LanguageContext';
import Iridescence from '../components/Iridescence/Iridescence';
import Stepper, { Step } from '../components/Stepper/Stepper';
import { toast } from 'react-toastify';
import { isSsnValid } from '../utils/isSsnValid';

const Register: React.FC = () => {
  // Form fields
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ssn, setSSN] = useState('');
  const [hasAgreed, setHasAgreed] = useState(false);

  const navigate = useNavigate();

  // Context vars
  const auth = useContext(AuthContext);
  const languageContext = useContext(LanguageContext) as LanguageContextType;
  const { language, setLanguage } = languageContext;

  //params
  const companySlug = useParams().companySlug ?? "";

  // Next button state management
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  type Step = "Fullname" | "Email" | "Password&SSN" | "Done";
  const stepsOrder: Step[] = ["Fullname", "Email", "Password&SSN", "Done"];
  const [currentStep, setCurrentStep] = useState<Step>("Fullname");
  const indexOfStep = (step: Step) => stepsOrder.indexOf(step) + 1;
  const stepAtIndex = (index: number) => stepsOrder[index - 1];

  //check if input is valid
  useEffect(() => {
    const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

    switch (currentStep) {
      case "Fullname":
        setNextButtonDisabled(!fullname.trim());
        break;
      case "Email":
        setNextButtonDisabled(!email.trim() || !validateEmail(email));
        break;
      case "Password&SSN":
        setNextButtonDisabled(!password.trim() || !ssn.trim() || !hasAgreed || !isSsnValid(ssn));
        break;
      case "Done":
        setNextButtonDisabled(false);
        break;
    }
  }, [fullname, email, password, ssn, hasAgreed, currentStep]);

  // Language menu state and ref (for click-outside detection)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () =>
      document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const formatSSN = (value: string) => {
    const digitsOnly = value.replace(/\D/g, '');
    if (digitsOnly.length > 6) {
      return `${digitsOnly.slice(0, 6)}-${digitsOnly.slice(6, 10)}`;
    }
    return digitsOnly;
  };

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSSN(formatSSN(e.target.value));
  };

  // Trigger registration after the final step is complete (if inputs are filled and T&C is checked)
  const handleFinalStepCompleted = async () => {
    // Validate that all required fields are filled out.
    if (!fullname.trim() || !email.trim() || !password.trim() || !ssn.trim()) {
      toast.error(
        language === 'en'
          ? 'Please fill in all required fields.'
          : language === 'is'
          ? 'Vinsamlegast fyllið út öll nauðsynleg svæði.'
          : 'Proszę wypełnić wszystkie wymagane pola.',
        { position: 'top-center' }
      );
      return;
    }

    // Prevent completion if Terms & Conditions haven't been accepted.
    if (!hasAgreed) {
      toast.error(
        language === 'en'
          ? 'You must accept the Terms & Conditions'
          : language === 'is'
          ? 'Þú verður að samþykkja skilmálana'
          : 'Musisz zaakceptować regulamin',
        { position: 'top-center' }
      );
      return;
    }

    if (auth) {
      try {
        await auth.register(email, password, fullname, ssn, companySlug);
        navigate(companySlug ? `/${companySlug}/login` : '/login');
      } catch (error) {
        setCurrentStep('Fullname');
      }
    }
  };

  // Language helpers
  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    setIsMenuOpen(false);
  };

  const getLanguageFlag = () => {
    switch (language) {
      case 'en': return '🇬🇧';
      case 'is': return '🇮🇸';
      case 'pl': return '🇵🇱';
      default: return '🌐';
    }
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      {/* Background Iridescence */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 0,
        }}
      >
        <Iridescence />
      </div>

      {/* Main Content Container with horizontal padding */}
      <div
        className="min-h-screen flex items-center justify-center relative px-4"
        style={{ zIndex: 1 }}
      >
        {/* LANGUAGE MENU */}
        <div className="absolute top-4 right-4" ref={menuRef}>
          <div className="relative">
            <button
              className="text-white focus:outline-none text-3xl hover:scale-110 transition-transform duration-300"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {getLanguageFlag()}
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-56 p-2 bg-white/30 backdrop-blur-md rounded-md border border-white/20 shadow-lg ring-1 ring-white ring-opacity-40 flex flex-col items-stretch animate-fadeIn z-20">
                <button
                  onClick={() => changeLanguage('en')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇬🇧 English
                </button>
                <button
                  onClick={() => changeLanguage('is')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇮🇸 Íslenska
                </button>
                <button
                  onClick={() => changeLanguage('pl')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇵🇱 Polski
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Registration Card Container with a New Gradient Background */}
        <div className="max-w-lg w-full mx-4 bg-gradient-to-br from-pink-100 via-purple-100 to-pink-100 p-10 rounded-[2.5rem] shadow-lg transform transition-all duration-500 hover:scale-105">
          <div className="flex justify-center mb-8">
            <img src="/testlogo.png" alt="Logo" className="h-12 w-12" />
          </div>
          <h2 className="text-4xl font-extrabold mb-8 text-purple-500 text-center tracking-wider">
            {language === 'en'
              ? 'Register'
              : language === 'is'
              ? 'Skrá'
              : 'Zarejestruj się'}
          </h2>

          {/* Stepper Component with additional horizontal padding in its content */}
          <Stepper
            currentStep={indexOfStep(currentStep)}
            setCurrentStep={(index) => setCurrentStep(stepAtIndex(index))}
            nextButtonProps={{disabled: nextButtonDisabled, style: {...(nextButtonDisabled ? {cursor: 'not-allowed', backgroundColor: "gray"} : {})}}}
            onFinalStepCompleted={handleFinalStepCompleted}
            backButtonText={
              language === 'en'
                ? 'Back'
                : language === 'is'
                ? 'Til baka'
                : 'Wstecz'
            }
            nextButtonText={
              language === 'en'
                ? 'Next'
                : language === 'is'
                ? 'Næst'
                : 'Dalej'
            }
            contentClassName="mx-6"
          >
            {/* STEP 1: Full Name */}
            <Step>
              <div className="mb-6 mx-1">
                <label className="block text-lg font-medium text-gray-700">
                  {language === 'en'
                    ? 'Full Name'
                    : language === 'is'
                    ? 'Fullt nafn'
                    : 'Imię i nazwisko'}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform duration-200 ease-in-out transform active:scale-100"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  required
                />
              </div>
            </Step>

            {/* STEP 2: Email */}
            <Step>
              <div className="mb-6 mx-1">
                <label className="block text-lg font-medium text-gray-700">
                  {language === 'en'
                    ? 'Email'
                    : language === 'is'
                    ? 'Netfang'
                    : 'Email'}
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform duration-200 ease-in-out transform focus:scale-100"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </Step>

            {/* STEP 3: Password, SSN & Terms Agreement */}
            <Step>
              <div className="mb-6 mx-1">
                <label className="block text-lg font-medium text-gray-700">
                  {language === 'en'
                    ? 'Password'
                    : language === 'is'
                    ? 'Lykilorð'
                    : 'Hasło'}
                </label>
                <input
                  type="password"
                  className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform duration-200 ease-in-out transform focus:scale-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="mb-6 mx-1">
                <label className="block text-lg font-medium text-gray-700">
                  {language === 'en'
                    ? 'Social Security Number'
                    : language === 'is'
                    ? 'Kennitala'
                    : 'Numer PESEL'}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform duration-200 ease-in-out transform focus:scale-100"
                  value={ssn}
                  onChange={handleSSNChange}
                  required
                />
              </div>

              <div className="mb-6">
                <label className="inline-flex items-center text-gray-700 text-base">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-purple-500"
                    checked={hasAgreed}
                    onChange={(e) => setHasAgreed(e.target.checked)}
                  />
                  <span className="ml-2">
                    {language === 'en'
                      ? 'I accept the '
                      : language === 'is'
                      ? 'Ég samþykki '
                      : 'Akceptuję '}
                    <Link to="/terms" className="text-purple-500 hover:underline">
                      {language === 'en'
                        ? 'Terms & Conditions'
                        : language === 'is'
                        ? 'Skilmála'
                        : 'Regulamin'}
                    </Link>
                  </span>
                </label>
              </div>
            </Step>
          </Stepper>

          {/* Login Link */}
          <p className="mt-8 text-center text-gray-600 text-base">
            {language === 'en'
              ? 'Already have an account?'
              : language === 'is'
              ? 'Ertu með aðgang?'
              : 'Masz już konto?'}{' '}
            <Link
              to={companySlug ? `/${companySlug}/login` : "/login"}
              className="text-purple-500 hover:underline transition-all duration-200"
            >
              {language === 'en'
                ? 'Login'
                : language === 'is'
                ? 'Innskrá'
                : 'Zaloguj się'}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;

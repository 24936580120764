import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { apiDoctorsList } from '../api/doctor';
import DoctorProfileModal from '../components/DoctorProfileModal';
import { Doctor } from '../types/doctor';
import { useNavigate } from 'react-router-dom';
import { Company } from '../api/auth';
import { apiGetCompanyList } from '../api/company';
import { doc } from 'firebase/firestore';

import {
  FaUserMd,
  FaUserNurse,
  FaUserShield,
  FaUsers,
  FaDatabase,
} from 'react-icons/fa';

const logoSrc = '/testlogo.png'; // Placeholder logo path

const ClinicDirectorView: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<
    'all' | 'doctors' | 'nurses' | 'staff' | 'asaData'
  >('all');
  const [selectedPerson, setSelectedPerson] = useState<Doctor | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Access your auth context
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  // Placeholder data for Nurses and Support Staff
  const nurses: Doctor[] = [
    { fullname: 'Alice Florence', email: 'alice.florence@clinic.is', role: 'Nurse' },
    { fullname: 'Grant Helgason', email: 'grant.helgason@clinic.is', role: 'Nurse' },
  ];
  const supportStaff: Doctor[] = [
    { fullname: 'Bobby Crane', email: 'bobby.crane@clinic.is', role: 'Support Staff' },
    { fullname: 'Stefanía Johansson', email: 'stefania.j@clinic.is', role: 'Support Staff' },
  ];

  // Fetch real doctors from the backend
  useEffect(() => {
    fetchDoctors();
  }, []);


  const fetchDoctors = async () => {
    try {
      setLoading(true);
      if (!auth?.user?.company.id) throw new Error('Auth error');
      
      const res = await apiDoctorsList(auth?.user?.company.id);
      setDoctors(res.data.doctors);
    } catch (error) {
      alert('Error loading doctors');
    } finally {
      setLoading(false);
    }
  };

  // Open and close modal
  const openModal = (person: Doctor) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPerson(null);
    setIsModalOpen(false);
  };

  // Handle Logout: calls the `logout` method from your AuthContext
  const handleLogout = async () => {
    try {
      await auth?.logout(); 
      // Your AuthContext's logout function already navigates to "/login",
      // but you could also do: navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Helper to render staff cards
  const renderStaffCards = (staffArray: Doctor[], roleColor: string) => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-48">
          <ClipLoader size={50} color="#ffffff" />
        </div>
      );
    }
    if (staffArray.length === 0) {
      return <p className="text-center text-gray-200">No staff found.</p>;
    }
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {staffArray.map((person, idx) => (
          <div
            key={idx}
            className="bg-white/20 backdrop-blur-lg rounded-xl p-6 flex flex-col 
                       shadow-lg border border-white/10 hover:shadow-2xl 
                       transition-transform transform hover:scale-105"
          >
            <h4 className="text-lg font-bold text-white mb-1">{person.fullname}</h4>
            <p className="text-sm text-gray-200">{person.email}</p>
            <p className={`text-sm font-semibold mt-1 ${roleColor}`}>
              {person.role}
            </p>
            <button
              onClick={() => openModal(person)}
              className="mt-auto bg-pink-500 text-white px-4 py-2 
                         rounded-full shadow-md hover:bg-pink-600 transition
                         font-medium self-start mt-4"
            >
              View
            </button>
          </div>
        ))}
      </div>
    );
  };

  // Render ASA data (placeholder)
  const renderAsaData = () => (
    <div className="flex justify-center items-center h-48">
      <p className="text-gray-200 text-lg font-semibold">
        Ása data will be displayed here.
      </p>
    </div>
  );

  // Render content by active tab
  const renderContent = () => {
    if (activeTab === 'all') {
      return (
        <>
          <section className="mb-12">
            <h3 className="text-2xl md:text-3xl font-bold text-pink-300 mb-4">
              Doctors
            </h3>
            {renderStaffCards(doctors, 'text-pink-200')}
          </section>
          <section className="mb-12">
            <h3 className="text-2xl md:text-3xl font-bold text-green-300 mb-4">
              Nurses
            </h3>
            {renderStaffCards(nurses, 'text-green-200')}
          </section>
          <section>
            <h3 className="text-2xl md:text-3xl font-bold text-blue-300 mb-4">
              Support Staff
            </h3>
            {renderStaffCards(supportStaff, 'text-blue-200')}
          </section>
        </>
      );
    } else if (activeTab === 'doctors') {
      return renderStaffCards(doctors, 'text-pink-200');
    } else if (activeTab === 'nurses') {
      return renderStaffCards(nurses, 'text-green-200');
    } else if (activeTab === 'staff') {
      return renderStaffCards(supportStaff, 'text-blue-200');
    } else if (activeTab === 'asaData') {
      return renderAsaData();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-tr from-purple-800 via-indigo-900 to-gray-900 flex flex-col">
      {/* Header */}
      <header className="relative z-10 flex items-center justify-between px-6 py-4 bg-transparent">
        <div className="flex items-center space-x-4">
          <img
            src={logoSrc}
            alt="Clinic Logo"
            className="w-14 h-14 object-cover rounded-full 
                       border-4 border-white shadow-lg"
          />
          <div className="text-white">
            <h1 className="text-3xl md:text-4xl font-extrabold tracking-tight">
              Clinic Director
            </h1>
            <p className="text-sm text-gray-100 opacity-80">
              Manage Your Clinic Staff and Ása Data
            </p>
          </div>
        </div>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-700 text-white 
                     px-4 py-2 rounded-full font-semibold transition shadow"
        >
          Logout
        </button>
      </header>

      {/* Tabs */}
      <div className="flex justify-center mt-6">
        <div className="bg-white/10 backdrop-blur-sm p-3 rounded-full flex space-x-4">
          <button
            onClick={() => setActiveTab('all')}
            className={`px-4 py-2 rounded-full flex items-center space-x-2 
                        transform transition-transform hover:scale-105 ${
              activeTab === 'all'
                ? 'bg-gradient-to-r from-fuchsia-500 to-purple-700 text-white shadow-lg'
                : 'bg-transparent text-gray-300 hover:bg-fuchsia-500 hover:text-white'
            }`}
          >
            <FaUsers />
            <span>All</span>
          </button>
          <button
            onClick={() => setActiveTab('doctors')}
            className={`px-4 py-2 rounded-full flex items-center space-x-2 
                        transform transition-transform hover:scale-105 ${
              activeTab === 'doctors'
                ? 'bg-gradient-to-r from-pink-500 to-pink-700 text-white shadow-lg'
                : 'bg-transparent text-gray-300 hover:bg-pink-500 hover:text-white'
            }`}
          >
            <FaUserMd />
            <span>Doctors</span>
          </button>
          <button
            onClick={() => setActiveTab('nurses')}
            className={`px-4 py-2 rounded-full flex items-center space-x-2 
                        transform transition-transform hover:scale-105 ${
              activeTab === 'nurses'
                ? 'bg-gradient-to-r from-green-500 to-green-700 text-white shadow-lg'
                : 'bg-transparent text-gray-300 hover:bg-green-500 hover:text-white'
            }`}
          >
            <FaUserNurse />
            <span>Nurses</span>
          </button>
          <button
            onClick={() => setActiveTab('staff')}
            className={`px-4 py-2 rounded-full flex items-center space-x-2 
                        transform transition-transform hover:scale-105 ${
              activeTab === 'staff'
                ? 'bg-gradient-to-r from-blue-500 to-blue-700 text-white shadow-lg'
                : 'bg-transparent text-gray-300 hover:bg-blue-500 hover:text-white'
            }`}
          >
            <FaUserShield />
            <span>Staff</span>
          </button>
          <button
            onClick={() => setActiveTab('asaData')}
            className={`px-4 py-2 rounded-full flex items-center space-x-2 
                        transform transition-transform hover:scale-105 ${
              activeTab === 'asaData'
                ? 'bg-gradient-to-r from-indigo-500 to-blue-700 text-white shadow-lg'
                : 'bg-transparent text-gray-300 hover:bg-indigo-500 hover:text-white'
            }`}
          >
            <FaDatabase />
            <span>Ása Data</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <main className="flex-1 flex flex-col items-center justify-center px-4 py-8 md:px-8">
        <div
          className="w-full max-w-6xl bg-white/10 backdrop-blur-md 
                     rounded-3xl p-8 shadow-2xl border border-white/20 
                     transition-transform hover:scale-[1.01]"
        >
          {renderContent()}
        </div>
      </main>

      {/* Modal */}
      <DoctorProfileModal
        isOpen={isModalOpen}
        onClose={closeModal}
        doctor={selectedPerson}
      />
    </div>
  );
};

export default ClinicDirectorView;

import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index"
import { Role } from '../types/roles';

interface DefaultResponse {
    success: boolean,
    message: string
}

export interface Company {
  id: string,
  name: string,
  slug: string
}

export interface User {
    id: string,
    email: string,
    displayName: string | null,
    photoURL: string | null,
    role: Role,
    fullname: string
    ssn: string;
    company: Company;
}

export type RegisterResponse = AxiosResponse<DefaultResponse>

export type GetUserResponse = AxiosResponse<{user: User}>


export const apiRegister = (email: string, password: string, fullname: string, ssn: string, companySlug: string): Promise<RegisterResponse> =>
  api.post(`/api/auth/register`, { email, password, fullname, ssn, companySlug });

export const apiGetCurrentUser = async (): Promise<GetUserResponse> => 
  await api.get(`/api/auth/user`, { headers: (await getAuthorization()) })
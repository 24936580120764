import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { Role } from '../../types/roles';

const ProtectedRoute = ({ allowedRoles, children }: { children: JSX.Element, allowedRoles?: Role[] }) => {
  const auth = useContext(AuthContext);
  const { companySlug } = useParams();

  if(!auth || auth.userLoading){
    return <div className="flex justify-center items-center h-64">
              <ClipLoader size={50} color={"#3b82f6"} />
          </div>
  }

  if (!auth.user || (allowedRoles && !allowedRoles.includes(auth.user.role))) {
    return companySlug ?
          <Navigate to={`/${companySlug}/login`} replace />
          : <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';
import {
  apiGetAsaChatsForNurse,
  apiMarkChatAsDismissed,
  apiSendChatToDoctor,
  AsaChat,
  AsaChatsFilter,
  NurseRestrictedAsaChat,
  RestrictedAsaChat,
} from '../api/asaChat';
import Header from '../components/Header';
import { ChatModal } from '../components/ChatModal';
import { LanguageContext } from '../contexts/LanguageContext';
import ElasticSlider from '../components/ElasticSlider/ElasticSlider';

/** 
 * Collapsible Urgency Scale Info component.
 * Only the info cards are collapsible, not the ElasticSlider.
 */
const UrgencyScaleInfo: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const urgencyData = [
    {
      level: 1,
      title: "1 - Low Urgency",
      description:
        "Minor issues manageable with self‐care and over‐the‐counter remedies.",
    },
    {
      level: 2,
      title: "2 - Moderate Urgency",
      description:
        "Conditions that may require consultation but are usually manageable at home.",
    },
    {
      level: 3,
      title: "3 - High Urgency",
      description:
        "Situations where timely medical advice is recommended to avoid complications.",
    },
    {
      level: 4,
      title: "4 - Very High Urgency",
      description:
        "Conditions needing prompt attention to prevent serious issues.",
    },
    {
      level: 5,
      title: "5 - Critical",
      description:
        "Life-threatening situations requiring immediate emergency intervention.",
    },
  ];

  const getUrgencyTagColor = (urgency: number): string => {
    switch (urgency) {
      case 5:
        return 'bg-red-600 text-white';
      case 4:
        return 'bg-red-400 text-white';
      case 3:
        return 'bg-yellow-400 text-white';
      case 2:
        return 'bg-green-300 text-gray-800';
      case 1:
        return 'bg-green-200 text-gray-800';
      default:
        return 'bg-gray-300 text-gray-800';
    }
  };

  return (
    <div className="mt-4 w-full bg-gray-900 rounded-lg p-6">
      <div
        className="flex items-center justify-between cursor-pointer mb-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-xl font-bold">Urgency Scale Details</h3>
        <button className="text-xl text-white">{isOpen ? '▲' : '▼'}</button>
      </div>
      {isOpen && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {urgencyData.map((item) => (
            <div
              key={item.level}
              className="flex gap-4 items-start p-4 bg-gray-800 rounded-lg"
            >
              <div
                className={`flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full ${getUrgencyTagColor(
                  item.level
                )}`}
              >
                <span className="font-bold">{item.level}</span>
              </div>
              <div>
                <h4 className="font-bold text-sm">{item.title}</h4>
                <p className="text-xs text-gray-300 mt-1">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/** 
 * Main UrgentChatsList Component
 */
const UrgentChatsList: React.FC = () => {
  const { user } = useContext(AuthContext) as AuthContextType;
  const languageContext = useContext(LanguageContext);
  if (!languageContext) {
    throw new Error('LanguageContext is not provided.');
  }
  const { language, setLanguage } = languageContext;

  const [chats, setChats] = useState<NurseRestrictedAsaChat[]>([]);
  // Initial filter state – urgency is controlled via the slider.
  const [filter, setFilter] = useState<AsaChatsFilter>({ sortBy: 'newest' });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedChats, setExpandedChats] = useState<{ [key: string]: boolean }>({});
  const [existMoreChats, setExistMoreChats] = useState<boolean>(true);
  const [openedChatId, setOpenedChatId] = useState<string | null>(null);

  // Handler for "Senda á lækni" button
  const handleSendToDoctor = async (chatId: string) => {
    if (window.confirm('Ertu viss um að þú viljir senda spjall á lækni?')) {
      apiSendChatToDoctor(chatId);
    }
  };

  // Fetch chats whenever filter changes.
  const fetchChats = async (appendNewChats: boolean) => {
    setLoading(true);
    try {
      const response = await apiGetAsaChatsForNurse(filter);
      let fetchedChats = response.data.chats;

      if (filter.sortBy === 'newest') {
        fetchedChats = fetchedChats.sort((a, b) => b.createdAt - a.createdAt);
      } else if (filter.sortBy === 'oldest') {
        fetchedChats = fetchedChats.sort((a, b) => a.createdAt - b.createdAt);
      }
      setChats((oldChats) =>
        appendNewChats ? [...oldChats, ...fetchedChats] : fetchedChats
      );
      setExistMoreChats(!!response.data.nextPage);
    } catch (err) {
      console.error('Error fetching chats:', err);
      setError('Error fetching chats');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const appendNewChats = !!filter.startAfter;
    fetchChats(appendNewChats);
  }, [filter]);

  const handleMarkAsRead = async (chatId: string) => {
    if (window.confirm('Are you sure you want to mark this chat as read?')) {
      try {
        const result = await apiMarkChatAsDismissed(chatId);
        if (result.data.success) {
          setChats((prevChats) =>
            prevChats.map((chat) =>
              chat.id === chatId ? { ...chat, dismissed: true } : chat
            )
          );
        }
      } catch (err) {
        console.error('Error marking chat as read:', err);
      }
    }
  };

  const getUrgencyTagColor = (urgency: number): string => {
    switch (urgency) {
      case 5:
        return 'bg-red-600 text-white';
      case 4:
        return 'bg-red-400 text-white';
      case 3:
        return 'bg-yellow-400 text-white';
      case 2:
        return 'bg-green-300 text-gray-800';
      case 1:
        return 'bg-green-200 text-gray-800';
      default:
        return 'bg-gray-300 text-gray-800';
    }
  };

  const toggleExpanded = (chatId: string) => {
    setExpandedChats((prevState) => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  const truncateText = (text: string, length: number) =>
    text.length > length ? text.slice(0, length) + '...' : text;

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 text-white">
      <Header />
      <div className="w-full max-w-6xl p-8">
        {/* Language Selector */}
        <div className="flex items-center justify-end mb-6">
          <label htmlFor="language-select" className="mr-2 font-semibold">
            Language:
          </label>
          <select
            id="language-select"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="p-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-4 focus:ring-blue-500 shadow-lg"
          >
            <option value="is">Icelandic</option>
            <option value="en">English</option>
            <option value="pl">Polish</option>
          </select>
        </div>
        {/* Urgency Slider and Collapsible Info */}
        <div className="mb-8 p-4 bg-gray-800 rounded-xl shadow-lg flex flex-col items-center">
          <h2 className="text-2xl font-bold text-center mb-4">Urgency Scale</h2>
          <ElasticSlider
            defaultValue={3}
            startingValue={1}
            maxValue={5}
            isStepped={true}
            stepSize={1}
            leftIcon={<span>Low</span>}
            rightIcon={<span>High</span>}
            onChange={(val) =>
              setFilter((prevFilter) => ({
                ...prevFilter,
                urgency_from: val,
                urgency_to: val,
              }))
            }
          />
          <UrgencyScaleInfo />
        </div>
        {/* Chat List */}
        <div className="mt-8 space-y-8">
          {error && (
            <div className="text-center text-red-400 text-2xl font-semibold">
              {error}
            </div>
          )}
          {chats.length > 0 ? (
            chats.map((chat) => (
              <div
                key={chat.id}
                className={`p-8 rounded-xl shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30 bg-gray-800 transition-transform transform hover:scale-105 hover:bg-opacity-40 ${
                  chat.dismissed ? 'opacity-70' : 'opacity-100'
                }`}
              >
                {/* Chat card structured as a two-column grid */}
                <div className="grid grid-cols-6 gap-4">
                  {/* Left Column (Chat Details) */}
                  <div className="col-span-5">
                    <div className="flex items-center space-x-4">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${getUrgencyTagColor(
                          chat.urgency
                        )}`}
                      >
                        <span className="font-bold text-xl">{chat.urgency}</span>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="font-semibold text-2xl text-white mb-2">Patient</p>
                      <p className="text-sm text-gray-400">Búið til: {getTimeString(chat.createdAt)}</p>
                      <p className="text-sm text-gray-400">Uppfært: {getTimeString(chat.updatedAt)}</p>
                    </div>
                    <div className="mt-4">
                      <p className="text-lg text-gray-200">
                        Greining:{' '}
                        {expandedChats[chat.id]
                          ? chat.diagnosis
                          : truncateText(chat.diagnosis, 60)}
                      </p>
                      <button
                        onClick={() => toggleExpanded(chat.id)}
                        className="mt-2 px-4 py-2 text-blue-500 rounded hover:bg-gray-700 transition"
                      >
                        {expandedChats[chat.id] ? 'Sýna minna' : 'Sýna meira'}
                      </button>
                    </div>
                  </div>
                  {/* Right Column (Action Buttons) */}
                  {!chat.dismissed && (
                    <div className="col-span-1">
                      <div className="grid grid-cols-1 h-full">
                        <div className="flex flex-col h-full">
                          {/* "Senda á lækni" Button Column */}
                          <button
                            onClick={() => handleSendToDoctor(chat.id)}
                            className="bg-gradient-to-b from-green-500 to-green-600 text-white flex-1 flex flex-col items-center justify-center transition-colors duration-200 ease-in-out hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-green-400 rounded-t-md"
                            aria-label="Senda á lækni"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="text-xs font-bold mt-1">Senda á lækni</span>
                          </button>
                          {/* "Hafna" Button Column */}
                          <button
                            onClick={() =>
                              window.confirm('Are you sure you want to mark this chat as read?') && handleMarkAsRead(chat.id)
                            }
                            className="bg-gradient-to-b from-red-500 to-red-600 text-white flex-1 flex flex-col items-center justify-center transition-colors duration-200 ease-in-out hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-red-400 rounded-b-md"
                            aria-label="Hafna"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            <span className="text-xs mt-1">Hafna</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-400 text-2xl">Engin spjöll fundust.</p>
          )}
          {chats.length > 0 && existMoreChats && (
            <button
              onClick={() => {
                const lastChat = chats[chats.length - 1];
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  startAfter: lastChat.id,
                }));
              }}
              className="bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 transition-all focus:outline-none focus:ring-4 focus:ring-green-400"
              aria-label="Load more chats"
            >
              Hlaða fleiri spjöll
            </button>
          )}
        </div>
      </div>
      <ChatModal chatId={openedChatId} onClose={() => setOpenedChatId(null)} />
    </div>
  );
};

/** Helper for formatting timestamps */
const getTimeString = (timestampSeconds: number) => {
  const date = new Date(timestampSeconds * 1000);
  const dateDay = new Date(date);
  const today = new Date();
  dateDay.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (dateDay.getTime() === today.getTime()) {
    return formatTime(date) + ' í dag';
  } else if (dateDay.getTime() === yesterday.getTime()) {
    return formatTime(date) + ' í gær';
  } else {
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }
};

function formatTime(date: Date) {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export default UrgentChatsList;

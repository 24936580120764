import React from "react";

export function formatGptTextToJSX(text: string): JSX.Element {
  const lines = text.split('\n');
  const elements: JSX.Element[] = [];
  let listItems: JSX.Element[] = [];
  let insideList = false;

  lines.forEach((line, index) => {
    const trimmedLine = line.trim();
    const listItemMatch = trimmedLine.match(/^\d+\.\s+(.*)/);

    if (listItemMatch) {
      // We found a line that starts with something like "1. text"
      listItems.push(<li key={`li-${index}`}>{formatLine(listItemMatch[1])}</li>);
      insideList = true;
    } else {
      // If we were inside a list, close it out before starting a new line
      if (insideList) {
        elements.push(
          <ol key={`ol-${index}`} className="font-bold list-decimal ml-5">
            {listItems}
          </ol>
        );
        listItems = [];
        insideList = false;
      }

      // Normal line (not a list item)
      elements.push(
        <React.Fragment key={`line-${index}`}>
          {formatLine(trimmedLine)}
          <br />
        </React.Fragment>
      );
    }
  });

  // If we ended while still inside a list, render those remaining list items
  if (insideList) {
    elements.push(
      <ol key="ol-end" className="font-bold list-decimal ml-5">
        {listItems}
      </ol>
    );
  }

  return <div>{elements}</div>;
}

function formatLine(text: string): JSX.Element {
  // This regex looks for either a URL or a bold pattern
  const combinedPattern = /(https?:\/\/[^\s]+)|(\*\*(.*?)\*\*)/g;

  const parts: Array<string | JSX.Element> = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = combinedPattern.exec(text)) !== null) {
    // Add any text before this match
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    if (match[1]) {
      // It's a URL
      const url = match[1];
      parts.push(
        <a
          key={`link-${match.index}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white underline"
        >
          {url}
        </a>
      );
    } else if (match[2]) {
      // It's a bold pattern (match[3] is the text without the **)
      parts.push(<strong key={`bold-${match.index}`}>{match[3]}</strong>);
    }

    lastIndex = combinedPattern.lastIndex;
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return <>{parts}</>;
}

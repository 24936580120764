import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index";

export type DoctorListResponse = AxiosResponse<{doctors: {email: string, role: "doctor", fullname: string}[]}>

export type CreateDoctorResponse = AxiosResponse<{success: boolean, message: string}>


export const apiDoctorsList = async (companyId: string): Promise<DoctorListResponse> => 
    await api.get(`/api/doctor/list/${companyId}`, { headers: (await getAuthorization()) });

export const apiCreateDoctor = async (email: string, password: string, fullname: string, companyId: string): Promise<CreateDoctorResponse> => 
    await api.post(`/api/doctor/create/${companyId}`, {email, password, fullname}, { headers: (await getAuthorization()) });
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Home: React.FC = () => {
  const auth = useContext(AuthContext);
  const { companySlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth?.user){
      console.log(auth.user.role)
      switch (auth.user.role) {
        case "user":
          navigate(`/chat`)
          break;
        case "nurse":
          navigate(`/view-urgent-chats`)
          break;
        case "doctor":
          navigate(`/doctor-ui`)
          break;
        case "admin":
          navigate(`/doctor-list`)
          break;
      }
    }
    
  }, [auth])

  if (!auth || !auth.user) {
    return <p>Loading...</p>;
  }
  
  
  const { email, displayName, fullname, role } = auth.user;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded shadow">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Welcome, {displayName || fullname || email}</h2>
        <p className="text-gray-700 mb-4">Role: {role}</p>

        {/* Content based on user role */}
        {role === 'admin' && (
          <div>
            <p className="mb-4">As an admin, you can manage doctors.</p>
                <Link to="/doctor-list" className="block w-full bg-blue-600 text-white mb-2 py-2 px-4 rounded text-center hover:bg-blue-700 mb-">
                    View All Doctors
                </Link>
                <Link
                to="/create-doctor"
                className="block w-full bg-blue-600 text-white py-2 px-4 rounded text-center hover:bg-blue-700 mb-4"
                >
                Create Doctor
                </Link>
          </div>
        )}

        {role === 'doctor' && (
          <p className="text-gray-700">As a doctor, you have access to patient management features (future feature).</p>
        )}

        {role === 'user' && (
          <div>
            <p className="mb-4">As a user, you can participate in chats.</p>
            <Link
              to="/chat"
              className="block w-full bg-blue-600 text-white py-2 px-4 rounded text-center hover:bg-blue-700 mb-4"
            >
              Go to Chat
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
// Login.tsx
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import Iridescence from '../components/Iridescence/Iridescence';
import { toast } from 'react-toastify';
import { IS_DOWNTIME } from '../constants';
import Downtime from './Downtime';
import ShapeBlur from '../components/ShapeBlur/ShapeBlur';

const Login: React.FC = () => {
  // Login form state (email & password)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // Auth and language contexts
  const auth = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const [bypassDowntime, setBypassDowntime] = useState(false);

  if (!languageContext) {
    throw new Error('LanguageContext must be used within a LanguageProvider');
  }
  const { language, setLanguage } = languageContext;
  const { companySlug } = useParams();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Language helper functions
  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    setIsMenuOpen(false);
  };

  const getLanguageFlag = () => {
    switch (language) {
      case 'en': return '🇬🇧';
      case 'is': return '🇮🇸';
      case 'pl': return '🇵🇱';
      default: return '🌐';
    }
  };

  // Handle login submission with error handling for incorrect credentials
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (auth) {
      try {
        const result = await auth.login(email, password);
        if (result === "invalid-credentials") {
          throw new Error(
            language === 'en'
              ? 'Incorrect email or password.'
              : language === 'is'
              ? 'Rangt netfang eða lykilorð.'
              : 'Niepoprawny email lub hasło.'
          );
        }
        // Optionally, handle other cases (e.g., undefined) here.
      } catch (error: any) {
        toast.error(error.message || 'An error occurred during login');
      }
    }
  };

  if (IS_DOWNTIME && !bypassDowntime) {
    return <Downtime bypassDowntime={() => {console.log("hello"); setBypassDowntime(true)}}/>;
  }

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      {/* Background Iridescence */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 0,
        }}
      >
        <Iridescence />
      </div>

      {/* Main Container with horizontal padding */}
      <div
        className="min-h-screen flex items-center justify-center relative px-4"
        style={{ zIndex: 1 }}
      >
        {/* LANGUAGE MENU */}
        <div className="absolute top-4 right-4" ref={menuRef}>
          <div className="relative">
            <button
              className="text-white focus:outline-none text-3xl hover:scale-110 transition-transform duration-300"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {getLanguageFlag()}
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-56 p-2 bg-white/30 backdrop-blur-md rounded-md border border-white/20 shadow-lg ring-1 ring-white ring-opacity-40 flex flex-col items-stretch animate-fadeIn z-20">
                <button
                  onClick={() => changeLanguage('en')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇬🇧 English
                </button>
                <button
                  onClick={() => changeLanguage('is')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇮🇸 Íslenska
                </button>
                <button
                  onClick={() => changeLanguage('pl')}
                  className="block w-full text-left px-6 py-3 text-base text-white font-medium rounded-md hover:bg-white/20 transition-all duration-200"
                >
                  🇵🇱 Polski
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Login Card Container */}
        <div className="max-w-lg md:max-w-lg w-full mx-4 bg-gradient-to-br from-pink-100 via-purple-100 to-pink-100 p-10 rounded-[2.5rem] shadow-lg transform transition-all duration-500 hover:scale-105">
          <div className="flex justify-center mb-8">
            <img src="/logo2.png" alt="Logo" className="h-12 w-12" />
          </div>
          <h2 className="text-4xl font-extrabold mb-8 text-purple-500 text-center tracking-wider">
            {language === 'en'
              ? 'Login'
              : language === 'is'
              ? 'Innskrá'
              : 'Zaloguj się'}
          </h2>

          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label className="block text-lg font-medium text-gray-700">
                {language === 'en' ? 'Email' : language === 'is' ? 'Netfang' : 'Email'}
              </label>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 
                           focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform 
                           duration-200 ease-in-out transform focus:scale-105"
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-gray-700">
                {language === 'en' ? 'Password' : language === 'is' ? 'Lykilorð' : 'Hasło'}
              </label>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 mt-2 text-gray-900 rounded-lg bg-gray-100 border border-gray-200 
                           focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-sm transition-transform 
                           duration-200 ease-in-out transform focus:scale-105"
              />
            </div>
            <button
              type="submit"
              className="w-full px-5 py-3 bg-purple-500 text-white font-bold rounded-full shadow-md hover:bg-purple-600 
                         transition-all duration-300"
            >
              {language === 'en' ? 'Login' : language === 'is' ? 'Innskrá' : 'Zaloguj się'}
            </button>
          </form>

          <p className="mt-8 text-center text-gray-600 text-base">
            {language === 'en'
              ? "Don't have an account?"
              : language === 'is'
              ? "Ertu ekki með aðgang?"
              : "Nie masz konta?"}{" "}
            <Link
              to={companySlug ? `/${companySlug}/register` : "/register"}
              className="text-purple-500 hover:underline transition-all duration-200"
            >
              {language === 'en'
                ? 'Register'
                : language === 'is'
                ? 'Skráðu þig'
                : 'Zarejestruj się'}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;

export const isSsnValid = (ssn: string) => {
  const ssnDigits = ssn.match(/\d/g)?.join('');

  if (!ssnDigits || ssnDigits.length != 10) return false;
  if (![9, 0].includes(parseInt(ssnDigits[9]))) return false;

  const checkWeights = [3, 2, 7, 6, 5, 4, 3, 2];
  const actualCheckNumber = parseInt(ssnDigits[8]);
  
  let checkSum = 0;
  for (let i = 0; i < 8; i++){
    checkSum += parseInt(ssnDigits[i]) * checkWeights[i];
  }
  checkSum += actualCheckNumber;

  return (checkSum % 11) == 0;
}
import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index"

export interface Company {
    id: string,
    name: string,
    slug: string
}

export type GetCompanyResponse = AxiosResponse<{company: Company}>

export type GetCompanyListResponse = AxiosResponse<{companies: Company[]}>


export const apiGetCompany = async (companySlug: string): Promise<GetCompanyResponse> =>
  await api.get(`/api/company/${companySlug}`, { headers: (await getAuthorization()) });

export const apiGetCompanyList = async (): Promise<GetCompanyListResponse> =>
  await api.get(`/api/company`, { headers: (await getAuthorization()) });
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const DoctorRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useContext(AuthContext);

  if (!auth || !auth.user || !auth.isDoctor) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default DoctorRoute;